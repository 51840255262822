<template>
  <div class="has-text-centered">
    <div style="font-family: cabin_sketch_regular;font-size: 32px;">
      {{ $t('sketchy.page_jeu.etat_3.devines') }}
    </div>

    <div class="field has-addons">
      <p class="control is-expanded">
        <textarea
          class="input"
          :class="{
            'is-warning' : !motEnvoye,
            'is-success' : motEnvoye,
          }"
          style="resize: none;overflow: hidden;min-height: 40px;font-family: cabin_sketch_regular;"
          :style="{
            height: textAreaHeight,
            'border-bottom-right-radius': textAreaHeightRaw > 40 ? '4px' : undefined,
          }"
          @input="recalculateHeight"
          @keyup.enter="sendMot"
          @keypress.enter="$event.preventDefault()"
          v-model="motDevine"
          :maxlength="$sketchy_mot_max"
          :placeholder="$t($locale === 'fr' ? placeholderFirst : placeholderSecond) + ' ' + $t($locale === 'fr' ?  placeholderSecond : placeholderFirst) "
          ref="textArea"
        />
      </p>
      <p class="control">
        <button
          class="button send-mot-button"
          :class="{
            'is-warning' : !motEnvoye,
            'is-success' : motEnvoye,
            'is-outlined': boutonEtat === 2,
          }"
          @click="sendMot"
        >
          <template v-if="boutonEtat === 0">{{ $t('sketchy.page_jeu.etat_3.envoi_mot') }}</template>
          <template v-else-if="boutonEtat === 1">{{ $t('sketchy.page_jeu.etat_3.envoi_ok') }}</template>
          <template v-else-if="boutonEtat === 2">{{ $t('sketchy.page_jeu.etat_3.modifier') }}</template>
          <template v-else-if="boutonEtat === 3">{{ $t('sketchy.page_jeu.etat_3.modifier_ok') }}</template>
        </button>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.picture-cadre {
  display: inline-block;

  // Pour le look
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 6px;
  background-color: white;

  // Pour éviter que l'utilisateur sélectionne par erreur le canvas avec le doigt
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none; /* Disable selection/copy in UIWebView */
}

.send-mot-button {
  &.is-outlined {
    background-color: rgba(0, 60, 0, 0.08) !important;
    color: #2e814a;

    &:hover, &:active,, &:focus {
      color: #1f5b33;
    }
  }
}
</style>

<script>
import debounce from 'lodash/debounce';

const placeholderFirst = [
  'sketchy.page_jeu.etat_3.placeholder_first_random.0',
  'sketchy.page_jeu.etat_3.placeholder_first_random.1',
  'sketchy.page_jeu.etat_3.placeholder_first_random.2',
  'sketchy.page_jeu.etat_3.placeholder_first_random.3',
  'sketchy.page_jeu.etat_3.placeholder_first_random.4',
  'sketchy.page_jeu.etat_3.placeholder_first_random.5',
];

const placeholderSecond = [
  'sketchy.page_jeu.etat_3.placeholder_second_random.1',
  'sketchy.page_jeu.etat_3.placeholder_second_random.2',
  'sketchy.page_jeu.etat_3.placeholder_second_random.3',
  'sketchy.page_jeu.etat_3.placeholder_second_random.4',
  'sketchy.page_jeu.etat_3.placeholder_second_random.5',
  'sketchy.page_jeu.etat_3.placeholder_second_random.6',
  'sketchy.page_jeu.etat_3.placeholder_second_random.7',
  'sketchy.page_jeu.etat_3.placeholder_second_random.8',
  'sketchy.page_jeu.etat_3.placeholder_second_random.9',
  'sketchy.page_jeu.etat_3.placeholder_second_random.10',
];

export default {
  name: 'E3DevinerMotFormulaire',

  data() {
    return {
      motDevine: '',
      placeholderFirst: placeholderFirst[this.randBetween(0, placeholderFirst.length - 1)],
      placeholderSecond: placeholderSecond[this.randBetween(0, placeholderSecond.length - 1)],

      motEnvoye: false,

      boutonEtat: 0,
      changeBoutonEtatTimeout: null,

      textAreaHeightRaw: 5,
      textAreaHeight: '5px',
    };
  },

  methods: {
    recalculateHeight: function () {
        this.textAreaHeight = '5px';
        this.$nextTick(() => {
          this.textAreaHeightRaw = this.$refs.textArea.scrollHeight;
          this.textAreaHeight = (this.$refs.textArea.scrollHeight) + 'px';
        });
    },

    sendMot() {
      if (this.motDevine.length > 0) {
        this.$emit('sendMsg', 'e3_mot', this.motDevine);
        this.motEnvoye = true;

        this.changeEtatAfter(this.boutonEtat === 0 ? 1 : 3, 2, 1300);
      }
    },

    changeEtatAfter(boutonEtatNow, boutonEtatAfter, delay) {
      if (this.changeBoutonEtatTimeout !== null)
        clearTimeout(this.changeBoutonEtatTimeout);

      this.boutonEtat = boutonEtatNow;
      setTimeout(() => {
        this.boutonEtat = boutonEtatAfter;
      }, delay);
    },

    onMessage(msg_type, msg_data) {
      if (msg_type === 'e3_mot') {
        this.motDevine = msg_data;
        this.motEnvoye = true;
        this.boutonEtat = 2;
      }
    },
  },
}
</script>
